import { Component, inject, Input } from '@angular/core';
import { IconComponent } from "@shared/ui-components/icon/icon.component";
import { Router } from '@angular/router';
import { NavigationHelper } from '@app/shared/utils/nav/nav-helper';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-header-toolbar',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslateModule],
  templateUrl: './header-toolbar.component.html',
  styleUrl: './header-toolbar.component.scss',
})
export class HeaderToolbarComponent  {
  private readonly router: Router = inject(Router);
  private readonly navigationService = inject(NavigationService);

  @Input() urlPath?: string;
  @Input() expanded: boolean = false;

  public pageTitle = this.navigationService.getPageName();

  onNavigateAction() {
    NavigationHelper.navigateTo(this.router, this.urlPath ?? '/');
  }

  toggleExpand (): void {
    this.expanded = !this.expanded;
  }

  isHomePage(): boolean {
    return this.router.url === '/';
  }
}
